import React, { useContext, useState, useEffect } from "react";
import "../widgets/widgetcss/styles.css";
import { MapContext } from "../../../../MapContext";
import "../../../../Judumas.css";


export default function ContextLayers() {

    const[gridToggleArr, setGridToggleArr] = useState([true, true, true, true, true, true, true, true])

    const mapContext = useContext(MapContext);

    function toggleContextLayerVisibility(layerId) {
        mapContext.state.dataContextLayers.forEach((layer) => {
          if (layerId === layer.layerId) {
            layer.visible = !layer.visible;
          }
        });
      }
    
      function toggleContextLayerVisibilityURL(layerURL) {
        mapContext.state.dataContextLayers.forEach((layer) => {
          if (layerURL === layer.url) {
            layer.visible = !layer.visible;
            console.log(layer.visible)
          }
        });
      }

      useEffect(() => {
        console.log(gridToggleArr)
        
          
          for (let i = 0; i< gridToggleArr.length; i++){
            if (gridToggleArr[i] === true) {
              let str = "gridToggle" + i
              document.getElementById(str).click();
            }
          }
        
      },[])

    return (
        <>
            
            <table className="ToggleTable">
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"JAR objektai"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle0"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[0] = !arr[0]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibilityURL("https://opencity.vplanas.lt/server/rest/services/Hosted/Administraciniai_pastatai_su_100_ir_daugiau_darbuotoj%C5%B3/FeatureServer");
                        
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"Ugdymo įstaigos"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle1"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[1] = !arr[1]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibility(30);
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"Sveikatos priežiūros įstaigos"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle2"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[2] = !arr[2]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibility(28);
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"VT stotelės"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle3"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[3] = !arr[3]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibilityURL("https://services1.arcgis.com/vVI5TNykiYD9EhM5/ArcGIS/rest/services/Stotel%c4%97s/FeatureServer");
                      }}
                    />
                    <span class="slider round" ></span>
                  </label>
                </td>
              </tr>
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"VT maršrutai"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle4"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[4] = !arr[4]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibilityURL("https://arcgis.sisp.lt/arcgis/rest/services/VT/VT_marsutu_trasos/FeatureServer");
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"Juodosios dėmės"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle5"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[5] = !arr[5]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibilityURL("https://gis.vplanas.lt/arcgis/rest/services/Interaktyvus_zemelapis/Transportas/MapServer");  
                        console.log(mapContext.state.dataContextLayers)                   
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"Parkomatai"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle6"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[6] = !arr[6]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibilityURL("https://services1.arcgis.com/vVI5TNykiYD9EhM5/ArcGIS/rest/services/Parkomatai_2024_05_21/FeatureServer");  
                        console.log(mapContext.state.dataContextLayers)                   
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr className={"ToggleTableRow"}>
                <td className={"ToggleTableText"}>{"Dviračių saugyklos"}</td>
                <td>
                  <label class="switch">
                    <input
                      type="checkbox"
                      id={"gridToggle7"}
                      onClick={(e) => {
                        if ( e.clientX === 0 && e.clientY === 0){
                          return
                        }
                        let arr = gridToggleArr
                        arr[7] = !arr[7]
                        setGridToggleArr(arr)
                        toggleContextLayerVisibilityURL("https://services1.arcgis.com/vVI5TNykiYD9EhM5/ArcGIS/rest/services/Tikslios_saugykl%c5%b3_vietos/FeatureServer");  
                        console.log(mapContext.state.dataContextLayers)                   
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </td>
              </tr>
                </table>
            
        </>
    )
}