import React, { createContext, useReducer } from "react";
import  * as featureService from "./Services/serviceLayers";
import services from "./Services/services";


export const MapContext = createContext();

const initialState = {
    transportation: "Car",
    time: "Day",
    featureLayer: featureService.op_car_day,
    dataFeatureLayer: featureService.operatorData,
    data: "Operator",  
    url: services.op_car_month_url,
    dataurl: services.operatorData,
    zoomAndQueryFlag: 0,
    dateRange: null,
    selectedStreetArray: [],
    mapView: {zoom: 0},
    sideBarRequestPending: 0,
    enviroment: "local",
    compareDate: null,
    compareTask: 0,
    layerLoading: 0,
    compareLayer: null,
    extentGeometry: null,
    multiSelect: false,
    compareLayers: [],
    dataContextLayers: [],
    reachLayers: [],
    calendarResetDates: null,
    gridClickData: null,
    searchFlag: 0,
    searchToGraphic: false,
    contextDataVisible: false
}

const mapReducer = (state, action) => {
    if (action.mapView != null){
        return{
            ...state,
            mapView: action.mapView
        }
    }
    if (action.transportation != null) {
        return {
            ...state,
            transportation: action.transportation
        }
    }
    if (action.time != null) {
        return {
            ...state, 
            time: action.time
        }
    }
    if (action.data != null) {
        return {
            ...state, 
            data: action.data
        }
    }
    if (action.maxValue != null) {
        return {
            ...state,
            maxValue: action.maxValue
        }
    }
    if (action.featureLayer != null){
        return {
            ...state,
            featureLayer: action.featureLayer
        }
    }
    if (action.url != null) {
        return {
            ...state,
            url: action.url
        }
    }
    if (action.dataurl != null) {
        return {
            ...state,
            dataurl: action.dataurl
        }
    }
    if (action.dataFeatureLayer != null) {
        return {
            ...state,
            dataFeatureLayer: action.dataFeatureLayer
        }
    }
    if (action.dateRange != null) {
        if (action.dateRange === 0){
            return {
                ...state,
                dateRange: null
            }
        }
        else {
            return {
                ...state,
                dateRange: action.dateRange
            }
        }
    }
    if (action.dateRange != null) {
        return {
            ...state,
            dateRange: action.dateRange
        }
    }
    if (action.selectedStreetArray != null) {
        return {
            ...state,
            selectedStreetArray: action.selectedStreetArray
        }
    }
    if (action.selectedStreetId != null) {
        return {
            ...state,
            selectedStreetId: action.selectedStreetId
        }
    }
    if (action.clearFlag != null) {
        return {
            ...state,
            clearFlag: action.clearFlag
        }      
    }
    if (action.dateWithData != null) {
        return {
            ...state,
            dateWithData: action.dateWithData
        }      
    }
    if (action.sideBarRequestPending != null) {
        return {
            ...state,
            sideBarRequestPending: action.sideBarRequestPending
        }      
    }
    if (action.compareTask != null) {
        return {
            ...state,
            compareTask: action.compareTask
        }      
    }
    if (action.compareDate != null) {
        if (action.compareDate === 0){
            return {
                ...state,
                compareDate: null
            }
        }
        else {
            return {
                ...state,
                compareDate: action.compareDate
            }
        }  
    }
    if (action.layerLoading != null) {
        return {
            ...state,
            layerLoading: action.layerLoading
        }      
    }
    if (action.compareLayer != null) {
        return {
            ...state,
            compareLayer: action.compareLayer
        }      
    }
    if(action.extentGeometry != null) {
        return {
            ...state,
            extentGeometry: action.extentGeometry
        }      
    }
    if(action.multiSelect != null) {
        return {
            ...state,
            multiSelect: action.multiSelect
        }      
    }
    
    if(action.dataContextLayers != null) {
        if (action.dataContextLayers === undefined){
            return {
                ...state
            }
        }
        return {
            ...state,
            dataContextLayers: action.dataContextLayers
        }      
    }
    if(action.reachLayers != null) {
        if (action.reachLayers === undefined){
            return {
                ...state
            }
        }
        return {
            ...state,
            reachLayers: action.reachLayers
        }      
    }
    if (action.calendarResetDates != null) {
        console.log(action.calendarResetDates)
        if (action.calendarResetDates === 0){
            return {
                ...state,
                calendarResetDates: null
            }
        }
        else {
            return {
                ...state,
                calendarResetDates: action.calendarResetDates
            }
        }  
    }
    if (action.gridClickData != null) {
        console.log(action.gridClickData)
        if (action.gridClickData === 0){
            return {
                ...state,
                gridClickData: null
            }
        }
        else {
            return {
                ...state,
                gridClickData: action.gridClickData
            }
        }  
    }
    if (action.searchFlag != null){
        return{
            ...state,
            searchFlag: action.searchFlag
        }
    }
    if (action.searchToGraphic != null){
        return{
            ...state,
            searchToGraphic: action.searchToGraphic
        }
    }
    if (action.contextDataVisible != null){
        return{
            ...state,
            contextDataVisible: action.contextDataVisible
        }
    }
    //Nedet po situ nes neprieina iki jo
    if(action.compareLayers !== null ) {

        if (action.compareLayers === undefined){
            return {
                ...state
            }
        }
        return {
            ...state,
            compareLayers: action.compareLayers
        }      
    }
    
}



export function MapProvider(props) {
    const [state, dispatch] = useReducer (mapReducer, initialState)

    return <MapContext.Provider value={{ state, dispatch }}>{props.children}</MapContext.Provider>;
}